import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import { Helmet } from "react-helmet";
import LiveYogaWidget from "~sections/featured-story/components/CS-widget/live-yoga"
//import scrollTo from 'gatsby-plugin-smoothscroll';
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function FeaturedStoryThree() {
  return (
    <>
    <Helmet>
    <title>Automated Messaging Campaigns Boosted Live Yoga Teacher's Client Acquisition</title>
    <meta property="og:title" content="Automated Messaging Campaigns Boosted Live Yoga Teacher's Client Acquisition"/>
    <meta property="og:description" content="Live Yoga Teachers joined We-Connect in early 2023 to boost their client acquisition. Since then, they've seen an influx of new connections and relationships."/>
    <meta property="og:image" content="https://we-connect.io/live-yoga-teachers-joined-we-connect.png"/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content="https://we-connect.io/live-yoga-teachers"/>
    <meta property="twitter:card" content="summary_large_image"/>
    <meta property="twitter:site" content="@WeConnect_io"/>
    <meta property="twitter:title" content="Automated Messaging Campaigns Boosted Live Yoga Teacher's Client Acquisition "/>
    <meta property="twitter:description" content="Live Yoga Teachers joined We-Connect in early 2023 to boost their client acquisition. Since then, they've seen an influx of new connections and relationships."/>
    <meta property="twitter:image" content="https://we-connect.io/live-yoga-teachers-joined-we-connect.png"/>
    </Helmet>
    <PageWrapper headerConfig={header}>
      <LiveYogaWidget />
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}