import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Widget from './style'
import img from "~assets/image/customer-stories/live-yoga-teachers.png";
import { SuperTag, Link } from '~components';

export default function LiveYogaWidget(){
  
return(
      
<Widget>
  <Container>
  <Widget.Block>
    <Row className=" justify-content-lg-start">
    <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
    <Widget.Image>
            <img  src={img} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />
        </Widget.Image>
      </Col>
    <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
        <Widget.Box>
        <Link to="/customer-stories"><Widget.MiniHeading><Widget.BackButton textColor="#000"><i class='fas fa-angle-left'></i>  Customer Stories</Widget.BackButton> </Widget.MiniHeading></Link>
          <Widget.Heading as="h2"> <SuperTag value="Automated Messaging Campaigns Boosted Live Yoga Teacher's Client Acquisition"/> </Widget.Heading>
          
        </Widget.Box>
      </Col>
    </Row>
    </Widget.Block>

    <Widget.Block>
    <Row className="justify-content-lg-start">
    <Col xs="12" className="col-xl-4 col-lg-4 col-md-8 col-xs-10">
        <Widget.Card>
        <Widget.Company as="h2">Live Yoga Teachers</Widget.Company>
        <Widget.Text><a target="_blank" href="https://liveyogateachers.com/"> Live Yoga Teachers </a> is a tool for yoga teachers to manage their live stream classes and an easy-to-use platform for people to book classes. On top of the group live online classes, the platform now offers online private yoga classes and in-person/online corporate yoga classes.</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara> <b>Industry</b> </Widget.SubPara>
        <Widget.Text>Health and wellness</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara> <b>Employees</b> </Widget.SubPara>
        <Widget.Text>2</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara> <b>Features Used</b> </Widget.SubPara>
        <Widget.Text>Connection request, Smart sequences, event invitations, automated follow-ups</Widget.Text>
        </Widget.Card>
      </Col>
    <Col className="col-xl-7 col-lg-7 col-md-8 col-xs-10">

        <Widget.Title as="h2"><b>Say Goodbye to Imposter Syndrome with Automation</b></Widget.Title>
        <Widget.Text>At the start of the pandemic, Elodie Gythiel, co-founder of <a target="_blank" href="https://liveyogateachers.com/">Live Yoga Teachers</a>, started practicing yoga live stream, like many other yoga practitioners. She realized there was potential for live-streaming yoga to stay, and she founded Live Yoga Teachers – an online marketplace for students to find yoga classes and for teachers to market their classes.
        </Widget.Text>
        <Widget.Text>
        Elodie and her co-founder split the workload of running their company. While her co-founder handles the IT side of things, she manages the marketing, sales, and customer service for 2 types of clients: yoga teachers and yoga students. She explained to us, “I am only one person targeting two audiences, so I really need tools for that. That’s why I looked into We-Connect.”
        </Widget.Text>
        <Widget.Text>
        Prior to using We-Connect, Elodie wasn’t focusing on LinkedIn for her SEO Consultant activity. She was getting most of her leads via her close network and coworking space. 
        “Contacting people you don’t know is a little scary, and the imposter syndrome makes it really hard to actually send an invitation request.”
        </Widget.Text>
        <Widget.Text>
        That’s when Elodie found We-Connect. “We-Connect removed that pressure, that fear of sending a connection request,” said Elodie. You set it up, and it gets sent out when you’re not around, so you don’t worry about it. “Now that I’ve used We-Connect for the startup, I’m going to use it for my SEO business as well.”
        </Widget.Text>


        <Widget.Title as="h2"><b>How Elodie Provides Value in Cold Message Outreach</b></Widget.Title>
        <Widget.Text>Part of Live Yoga Teacher’s success using We-Connect can be tied directly to Elodie’s messaging. Instead of positioning herself as a salesperson marketing her platform, Elodie builds a relationship with each connection – explaining that she’s a yoga student creating a platform that will benefit both students and teachers.</Widget.Text>
        <Widget.Text>After perfecting her messaging through multiple tests, Elodie has built a 5-step sequence that’s both concise and precise. It starts with an invitation to connect, in which Elodie “makes sure that leads can’t say no.”</Widget.Text>
        <Widget.Text>The second step in the sequence dives into more details about Live Yoga Teachers. More importantly, Elodie’s messaging covers how Live Yoga teachers can add value to her prospect’s life.</Widget.Text>
        <Widget.Text>In the third part of the sequence, Elodie takes things to the next level by inviting contacts to a demo, and 4th sequence is an invite to a webinar about SEO for yoga teachers.</Widget.Text>
        <Widget.SubPara>“Leads on LinkedIn are often keen to know more about digital marketing, so this works pretty well, with a higher conversion rate than cold emailing, for example.”</Widget.SubPara>

        <Widget.CardTwo>
        <Widget.SubPara> Start your free trial and start kicking butt like this company did.</Widget.SubPara>
        <Widget.Button target="_blank" to="//account.we-connect.io/signup" className="btn btn-primary text-white">14 day free trial</Widget.Button>
        </Widget.CardTwo>
      </Col>
    </Row>
    </Widget.Block>
    
  </Container>
</Widget>

)
}